import { module } from 'modujs';
import { gsap } from "gsap";
import { CUSTOM_EVENT } from '../config';

export default class extends module {
    constructor(m) {
        super(m);

        this.scrollTargetX = 0;
        this.autoPlayTargetX = 0;
        this.lastAutoPlayTargetX = 0;
        this.lastX = 0;
        this.lastScroll = 0;
        this.scrollDirection = 1;

        this.baseWidth = 0;
        this.inViewport = false;

        this.timelines = [];

        this.root = this.el;
        this.$line = this.$("line")[0];
        this.$base = this.$("base")[0];

        this.isMobile = window.matchMedia('(max-width: 768px)').matches;
        this.isReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    }

    init() {
        if (this.isReducedMotion) return;

        this.repeat = parseInt(this.getData('repeat'), 10) || 0;
        this.gap = parseInt(this.getData('gap'), 10) || 0;
        this.autoPlaySpeed = parseFloat(this.getData('auto-play-speed')) || 1;
        this.direction = this.getData('direction') || 'left';

        this.bindEvents();
        this.observe();
        this.onResize();
    }

    destroy() {
        super.destroy();

        this.unbindEvents();

        this.intersectionObserver && this.intersectionObserver.disconnect();
    }

    bindEvents() {
        window.addEventListener(CUSTOM_EVENT.RESIZE_END, this.onResize.bind(this));
        window.addEventListener("scroll", this.onScroll.bind(this));

        gsap.ticker.add(this.onTick.bind(this));
    }

    unbindEvents() {
        window.removeEventListener(CUSTOM_EVENT.RESIZE_END, this.onResize.bind(this));
        window.removeEventListener("scroll", this.onScroll.bind(this));

        gsap.ticker.remove(this.onTick);
    }

    onScroll() {
        const currentScroll = window.scrollY || 0;

        if (currentScroll > this.lastScroll) {
            this.scrollDirection = 1;
        } else if (currentScroll < this.lastScroll) {
            this.scrollDirection = -1;
        }

        this.lastScroll = currentScroll;

        if (this.baseWidth) {
            this.scrollTargetX = currentScroll * 2 * (this.direction === 'left' ? 1 : -1);
        }
    }

    onResize() {
        if (!this.root || !this.$base) return;

        this.baseWidth = this.$base.offsetWidth;

        if (this.root.offsetWidth > 0 && this.baseWidth > 0) {
            this.repeat = Math.max(0, Math.ceil((this.root.offsetWidth + this.baseWidth) / this.baseWidth) - 1);
        }
    }

    onTick() {
        if (!this.$line || !this.$base) return;

        // Enable for autoplay:
        if (this.autoPlaySpeed) {
            this.autoPlayTargetX += this.autoPlaySpeed * 1.2 * this.scrollDirection * (this.direction === 'left' ? 1 : -1);
        }

        const targetX = this.getNextX(this.autoPlayTargetX + (this.isMobile ? 0 : this.scrollTargetX));

        if (this.lastX !== targetX) {
            this.lastX = targetX;

            if (this.inViewport) {
                gsap.set(this.$line, { x: -targetX });
            }
        }
    }

    getNextX(x) {
        const loopWidth = this.baseWidth + this.gap;
        let nextX = Math.round((x % loopWidth) * 100) / 100;
        return nextX < 0 ? nextX + loopWidth : nextX;
    }

    observe() {
        this.intersectionObserver = new IntersectionObserver((entries) => {
            this.inViewport = entries[0].isIntersecting;
        }, { threshold: 0 });

        if (this.root) {
            this.intersectionObserver.observe(this.root);
        }
    }
}
