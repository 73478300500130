import Modal from "./Modal";

export default class extends Modal {
    constructor(m) {
        super(m);

        this.focusTrapOptions = {
            ...this.focusTrapOptions,
            returnFocusOnDeactivate: false
        }

        this.events = {
            focusin: 'onFocus'
        }
    }

    onFocus(e) {
        document.getElementById('visible-header')?.scrollIntoView({
            block: "nearest",
            inline: "nearest"
        })
    }
}
