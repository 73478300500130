import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    onInview(e) {
        if(e.way == 'enter' && !window.matchMedia('(prefers-reduced-motion)').matches) {
            this.el.play()
        } else {
            this.el.pause()
        }
    }
}
