import { module } from 'modujs';
import { gsap, SplitText } from 'gsap/all';
import { CUSTOM_EVENT } from '../config';

gsap.registerPlugin(SplitText);

export default class extends module {
    constructor(m) {
        super(m);

        this.split = null;
        this.animating = false;

        this.$title = this.$('title')[0];

        this.isReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
        this.hasHover = window.matchMedia('(hover: hover)').matches;
    }

    init() {
        if (this.isReducedMotion || !this.hasHover) return;

        this.split = new SplitText(this.$title, { type: "lines" });

        this.split.lines.forEach(line => {
            const span = document.createElement('span');
            span.textContent = line.textContent;
            line.textContent = '';
            line.appendChild(span);
        });

        this.bindEvents();
    }

    destroy() {
        this.unbindEvents();
        this.split?.revert();
    }

    bindEvents() {
        window.addEventListener(CUSTOM_EVENT.RESIZE_END, this.onResize);

        this.el.addEventListener('mouseenter', this.onMouseEnter);
        this.el.addEventListener('mouseleave', this.onMouseLeave);
    }

    unbindEvents() {
        window.removeEventListener(CUSTOM_EVENT.RESIZE_END, this.onResize);

        this.el.removeEventListener('mouseenter', this.onMouseEnter);
        this.el.removeEventListener('mouseleave', this.onMouseLeave);
    }

    onMouseEnter = () => {
        if (this.animating) return;

        this.animating = true;

        const containerWidth = this.$title.offsetWidth;
        const lines = this.split?.lines || [];

        gsap.killTweensOf(lines);

        lines.forEach((line, i) => {
            const span = line.querySelector('span');
            const lineWidth = span.offsetWidth;

            gsap.to(line, {
                x: -(containerWidth - lineWidth),
                duration: 0.4,
                delay: i * 0.1,
                ease: "quart.out"
            });
        });
    };

    onMouseLeave = () => {
        const lines = this.split?.lines || [];

        gsap.killTweensOf(lines);

        gsap.to(lines, {
            x: 0,
            stagger: 0.1,
            duration: 1,
            ease: "quart.inOut",
            onComplete: () => {
                this.animating = false;
            }
        });
    };

    onResize = () => {
        this.split.revert();
        this.split = new SplitText(this.$title, { type: "lines" });

        this.split.lines.forEach(line => {
            const span = document.createElement('span');
            span.textContent = line.textContent;
            line.textContent = '';
            line.appendChild(span);
        });
    };
}
