/**
 * Determines if the argument is object-like.
 *
 * A value is object-like if it's not `null` and has a `typeof` result of "object".
 *
 * @param  {*} x - The value to be checked.
 * @return {boolean}
 */

const isObject = x => (x && typeof x === 'object')

/**
 * Determines if the argument is a function.
 *
 * @param  {*} x - The value to be checked.
 * @return {boolean}
 */

const isFunction = x => typeof x === 'function'

/**
 * Determines if the device is a touch device.
 * @return {boolean}
 */
const isTouchDevice = () => {
    return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    );
};

const isFirefox = () => {
    document.documentElement.classList.toggle(
        'is-firefox',
        navigator.userAgent.indexOf('Firefox') != -1
    );
}

const isSafari = () => {
    document.documentElement.classList.toggle(
        'is-safari',
        navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Version') != -1
    );
}

const isAndroid = () => {
    document.documentElement.classList.toggle(
        'is-android',
        navigator.userAgent.indexOf('Android') != -1
    );
}

export {
    isObject,
    isFunction,
    isTouchDevice,
    isFirefox,
    isSafari,
    isAndroid
}
