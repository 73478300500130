import { module } from 'modujs';
import { whenReady } from "../utils/fonts.js";
import { gsap, SplitText } from 'gsap/all';
import { CUSTOM_EVENT, FONT } from '../config.js';

gsap.registerPlugin(SplitText)

export default class extends module {
    constructor(m) {
        super(m);

        // Options
        this.ariaLabel = this.getData('aria-label') === 'true';
        this.revealWords = this.getData('reveal-words') === 'true';
        this.delay = parseFloat(this.getData('delay')) || 0;
        this.stagger = parseFloat(this.getData('stagger')) || 0.1;

        this.isReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    }

    init() {
        this.bindEvents()

        if (this.ariaLabel) {
            this.el.setAttribute('aria-label', this.el.innerText);
        }

        this.split();

        whenReady(FONT.EAGER).then((fonts) => this.onFontsLoaded(fonts));
    }

    destroy() {
        super.destroy()

        this.clear()
        this.unbindEvents()
    }

    bindEvents() {
        window.addEventListener(CUSTOM_EVENT.RESIZE_END, this.onResize);
    }

    unbindEvents() {
        window.removeEventListener(CUSTOM_EVENT.RESIZE_END, this.onResize);
    }

    ///////////////
    // Callbacks
    ///////////////
    onResize = () => {
       this.reSplit()
    }

    onFontsLoaded(fonts) {
        this.reSplit()
    }

    ///////////////
    // Methods
    ///////////////
    onInView(args) {
        if (args.way == 'leave') {
            this.clear()
        }
    }

    reveal() {
        if (this.isReducedMotion) return;

        const words = this.el.querySelectorAll('.u-word');
        this.introTL = gsap.timeline();
        this.introTL
            .set(words, {
                opacity: 0,
                yPercent: 100,
            })
            .addLabel('start', this.delay)
            .to(words, {
                opacity: 1,
                duration: 0,
                ease: 'none',
                stagger: this.stagger,
                clearProps: 'opacity'
            }, 'start')
            .to(words, {
                yPercent: 0,
                duration: 0.4,
                ease: 'quart.inOut',
                stagger: this.stagger,
                clearProps: 'yPercent'
            }, 'start')
    }

    split() {
        if (this.isReducedMotion) return;

        const el = this.el.querySelectorAll('.u-split')
        this.splitObject = new SplitText(el, { type: "words", wordsClass: "u-words-split u-word" });

        this.splitObject.words.forEach((line, i) => {
            line.style.setProperty('--line-index', i)
            line.setAttribute('aria-hidden', 'true')
        });

        if (this.revealWords) {
            this.reveal();
        } 
    }

    reSplit() {
        this.splitObject?.revert?.();

        requestAnimationFrame(() => {
            this.split();
        })
    }

    clear() {
        this.splitObject?.words?.forEach((line) => line.style.removeProperty('--line-index'));
        this.splitObject?.revert?.();
        this.splitObject = null;

        this.tlScroll?.kill?.();
        this.tlScroll = null;
    }
}
