import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            focusin: 'onFocus'
        }
    }

    onFocus(e) {
        this.$('focus-area')[0].scrollIntoView({
            block: "nearest",
            inline: "nearest"
        })
    }
}
