import { module } from 'modujs';
import { FONT } from '../config';
import { gsap, Flip } from "gsap/all";
import { whenReady } from '../utils/fonts';

gsap.registerPlugin(Flip);

export default class extends module {
    constructor(m) {
        super(m);

        this.innerW = window.innerWidth;
        this.$logo = this.$("logo")[0];
        this.$logoInner = this.$logo.querySelector(".svg-function-logo");

        this.isReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

        this.onResizeBind = this.onResize.bind(this);
    }

    init() {
        if (this.isReducedMotion) return;

        this.bindEvents();

        whenReady(FONT.EAGER).then((fonts) => this.onFontsLoaded(fonts));
    }

    destroy() {
        this.unbindEvents();

        this.tl?.kill?.();
        this.tl = null;
    }

    onFontsLoaded(fonts) {
        this.updateScale();
    }

    bindEvents() {
        window.addEventListener("resize", this.onResizeBind);
    }

    unbindEvents() {
        window.removeEventListener("resize", this.onResizeBind);
    }

    onResize() {
        if(this.innerW == window.innerWidth) return; // Ignore if only the width changed (prob mobile browser ui)

        this.innerW = window.innerWidth
        // Force a reflow to apply natural size
        this.$logo.offsetHeight;
        this.updateScale();
    }

    updateScale() {
        const progress = this.tl?.progress?.();
        this.tl?.kill?.();
        gsap.set(this.$logo, { clearProps: 'all' })

        const state = Flip.getState(this.$logo);
        Flip.fit(this.$logo, document.querySelector('.c-header_logo'), {
            scale: true
        });
        this.flip = Flip.from(state, { scale: true, ease: 'power2.inOut' });

        this.tl = gsap.timeline({ paused: true });
        this.tl
            .addLabel("start", 0.5)
            .add(this.flip, "start")

        this.tl.progress(progress ?? 0).pause();
    }


    onScrollProgress(value) {
        this.tl?.progress?.(value);
    }
}
