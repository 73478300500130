const detailRoutes = [
    "/event/:event",
    "/audio-details/:podcast",
    "/portrait/:portrait",
    "/blog/:article"
]

const listingRoutes = [
    "/",
    "/feed",
    "/feed/:page",
    "/events",
    "/events/:page",
    "/audio",
    "/audio/:page",
    "/portraits",
    "/portraits/:page",
    "/articles",
    "/articles/:page"
]

export default fragmentRules = [
    // Listings to/from details
    {
        from: listingRoutes,
        to: detailRoutes,
        containers: ['#dialog'],
        name: 'open-dialog'
    },
    {
        from: detailRoutes,
        to: listingRoutes,
        containers: ['#dialog', '#listing'],
        name: 'close-dialog'
    },
    // UPDATE DIALOG
    {
        from: detailRoutes,
        to: detailRoutes,
        containers: ['#dialog'],
        name: 'update-dialog'
    }
]
